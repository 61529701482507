function getTimeZone () {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  catch {
  }
}

export const currentTimeZone = getTimeZone()

// Set a cookie allowing the backend to use the browser's time zone.
if (currentTimeZone)
  document.cookie = `time_zone=${encodeURIComponent(currentTimeZone)}`
